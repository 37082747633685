import ApplicationController from "../../view_components/application_controller.js";
import get from "lodash/get";
import { colord } from "colord";
import { useColorHelper, useAssetLoader } from "mixins";
// import "@melloware/coloris/dist/coloris.css"; //! Dont import stlyes like this build system loops
import Coloris from "@melloware/coloris";

export default class extends ApplicationController {
  static targets = ["input", "eyedropper", "colorReset", "output", "ab"];
  static values = {
    db: { type: Object, default: {} },
    path: { type: String, default: "" },
    elementClasses: { type: Array, default: [] },
  };

  connect() {
    this.log_function("connect");

    useColorHelper(this);
    useAssetLoader(this);

    this.boundActivateColoris = this.activateColoris.bind(this);
    this.boundInitializeTool = this.initializeTool.bind(this);

    this.floating_contact_button = document.getElementById("floating_contact_button");
    this.has_floating_contact_button = Boolean(this.floating_contact_button);

    if(this.has_floating_contact_button) {
      this.floating_contact_button_original_style = this.floating_contact_button.getAttribute("style");
    }

    if (this.is_open) {
      this.open();
    }
  } //* connect()

  open(){
    this.log_function("open");

    this.element.src = this.pathValue;
    this.element.classList.add(...this.elementClassesValue);
    localStorage.setItem("color_picker_9000_open", true);

    //* Adds an event listener to activate the javascript when the turboframe has loaded.
    this.element.addEventListener("turbo:frame-load", this.boundInitializeTool);
  } //* open()

  initializeTool(event){
    this.log_function("initializeTool", event);

    useColorHelper(this);
    useAssetLoader(this);

    this.load_style("/obfuscated/@melloware/coloris.css");
    Coloris.init(); // eslint-disable-line import/no-named-as-default-member

    this.gt_output = {};

    this.initializeInputs("local", true);

    if(this.has_floating_contact_button) {
      this.floating_contact_button.setAttribute("style", `bottom: 250px !important; ${this.floating_contact_button_original_style}`);
    }

    this.outputTarget.value = JSON.stringify(this.gt_output);
  } //* initializeTool()

  activateColoris(event){
    this.log_function("activateColoris", event);

    Coloris({
      theme: "default",
      themeMode: "light",
      alpha: false,
      format: "hex",
    });
  } //* activateColoris()

  copyToClipboard(){
    this.log_function("copyToClipboard");

    const text = JSON.stringify(this.gt_output || {} ) || "";

    navigator.clipboard.writeText(text)
    .then(() => {
      this.log_function("copyToClipboard", "Text copied to clipboard");
      return true;
    })
    .catch((error) => {
      this.log_function("copyToClipboard", "Failed to copy text", error);
      return false;
    });
  } //* copyToClipboard()

  EyeDropper(event){
    this.log_function("EyeDropper", event);

    const eyeDropper = new EyeDropper();
    const target = event.target.closest("button");
    const cssVariableKey = target.dataset.cssVariableKey;
    const gtVariableKey = target.dataset.gtVariableKey;
    const inputId = target.dataset.inputId;
    const input = document.getElementById(inputId);
    const container = input.parentElement;
    const oldColor = target.style.color;
    const setEyedropperColor = (color) => { target.style.setProperty("color", color); };

    setEyedropperColor("transparent");

    eyeDropper
    .open()
    .then((result) => {
      const { sRGBHex } = result;
      this.log_function("EyeDropper", sRGBHex);
      input.value = sRGBHex;
      container.style.setProperty("color", sRGBHex);
      this.gt_output[gtVariableKey] = sRGBHex;
      this.setCSSColor(cssVariableKey, sRGBHex);
      this.saveColors(this.gt_output);
      this.checkResetButtonActivation();
      setEyedropperColor(oldColor);
      return result;
    })
    .catch((error) => {
      this.log_function("EyeDropper", "User probably eyeDropper operation", error);
      setEyedropperColor(oldColor);
      return error;
    });
  } //* EyeDropper()

  toggle(){
    this.log_function("toggle");

    this.is_open ? this.close() : this.open();
  } //* toggle()

  initializeInputs(mode = "local", first_run = false) {
    this.log_function("initializeInputs", `mode: ${mode} first_run: ${first_run}`);

    switch (mode) {
      case "local":
        this.mode = "local";
        this.abTarget.innerHTML = "View Saved Version";
        this.disabled_pickers = false;
        break;
      case "database":
        this.mode = "database";
        this.abTarget.innerHTML = "View Changed Version";
        this.disabled_pickers = true;
        break;
      default:
        this.log_error_function(`The initializeInputs mode ${mode} is not a valid value`);
        mode = "local";
        this.mode = "local";
        this.abTarget.innerHTML = "View Saved Version";
        this.disabled_pickers = false;
        break;
    }

    this.inputTargets.forEach(input => {
      const gtVariableKey = input.dataset.gtVariableKey;
      const cssVariableKey = input.dataset.cssVariableKey;
      const localStorageValue = get(this.saved_color_values, gtVariableKey, false);
      const dbValue = get(this.dbValue, gtVariableKey, false);
      const color_options = {
        local: localStorageValue || dbValue || "#FFFFFF",
        database: dbValue || localStorageValue || "#FFFFFF",
      };
      const colorToUse = color_options[mode];

      input.value = colorToUse;
      this.gt_output[gtVariableKey] = colorToUse;
      this.setCSSColor(cssVariableKey, colorToUse);

      input.disabled = this.disabled_pickers;
      if (first_run) input.addEventListener("click", this.boundActivateColoris);
    });

    this.eyedropperTargets.forEach(button => {
      button.disabled = this.disabled_pickers;
    });

    if (first_run) this.saveColors(this.gt_output);

    this.checkResetButtonActivation();
  } //* initializeInputs()

  ab(){
    this.log_function("ab");

    switch (this.mode) {
      case "local":
        this.initializeInputs("database");
        break;
      case "database":
        this.initializeInputs("local");
        break;
      default:
        this.log_error_function(`The ab mode ${this.mode} is not a valid value`);
        this.initializeInputs("local");
        break;
    }
  } //* ab()

  close(){
    this.log_function("close");

    this.element.src = "";
    this.element.classList.remove(...this.elementClassesValue);
    this.element.replaceChildren();

    if(this.has_floating_contact_button) {
      this.floating_contact_button.setAttribute("style", this.floating_contact_button_original_style);
    }

    localStorage.removeItem("color_picker_9000_open");
  } //* close()


  resetColor(event){
    this.log_function("resetColor", event);

    const target = event.target.closest("button");
    const cssVariableKey = target.dataset.cssVariableKey;
    const gtVariableKey = target.dataset.gtVariableKey;
    let newColor = get(this.dbValue, gtVariableKey, false);

    if(!newColor){
      const default_color = "#ffffff";
      this.log_error_function("resetColor", `color not detected correctly setting color to ${default_color}`);
      newColor = default_color;
    }

    this.gt_output[gtVariableKey] = newColor;
    this.setCSSColor(cssVariableKey, newColor);
    this.saveColors(this.gt_output);
    this.checkResetButtonActivation();
  } //* resetColor()

  checkResetButtonActivation(){
    this.log_function("checkResetButtonActivation");


    this.colorResetTargets.forEach(button => {
      const gtVariableKey = button.dataset.gtVariableKey;
      const localStorageValue = get(this.saved_color_values, gtVariableKey, false);
      const dbValue = get(this.dbValue, gtVariableKey, false);

      const color_is_changed = localStorageValue !== dbValue;
      const in_local_mode = this.mode === "local";

      if (color_is_changed && in_local_mode){
        button.style.opacity = 1;
        button.disabled = false;
      }else{
        button.style.opacity = 0;
        button.disabled = true;
      }
    });
  } //* checkResetButtonActivation()

  reset(){
    this.log_function("reset");

    localStorage.removeItem("color_picker_9000_colors");
    this.initializeInputs("local");
    this.saveColors(this.gt_output);
    this.checkResetButtonActivation();
  } //* reset()

  changeColor(event) {
    this.log_function("changeColor", event);

    const target = event.target;
    const cssVariableKey = target.dataset.cssVariableKey;
    const gtVariableKey = target.dataset.gtVariableKey;
    const newColor = target.value;

    this.gt_output[gtVariableKey] = newColor;
    this.setCSSColor(cssVariableKey, newColor);
    this.saveColors(this.gt_output);
    this.checkResetButtonActivation();
  } //* changeColor()

  saveColors(colors){
    this.log_function("saveColors", colors);

    const gt_output_string = JSON.stringify(colors);
    this.outputTarget.value = gt_output_string;
    localStorage.setItem("color_picker_9000_colors", gt_output_string);
  } //* saveColors()

  setCSSColor(cssVariableKey, color){
    this.log_function("saveColors", `cssVariableKey: ${cssVariableKey} color: ${color}`);

    if (cssVariableKey === "gt-cta-text") {
      const cta_text_shadow = this.hex_contrasted_color(
        colord(color).toHex(), {
        light_output: "none",
        dark_output: "var(--gt-text-shadow-sm)",
        threshold: 186,
        },
      );

      const cta_super_contrast = this.hex_contrasted_color(
        colord(color).toHex(), {
        light_output: "#FFFFFF",
        dark_output: "#000000",
        threshold: 186,
        },
      );

      document.documentElement.style.setProperty("--gt-cta-super-contrast", cta_super_contrast);
      document.documentElement.style.setProperty("--gt-cta-text", color);
      document.documentElement.style.setProperty("--bs-cta-text-shadow", cta_text_shadow);

      const accordion_button_color = colord(color).darken(0.20).toRgbString();
      const accordion_button = `url("data:image/svg+xml,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'0 0 16 16\' fill=\'${accordion_button_color}\'%3E%3Cpath fill-rule=\'evenodd\' d=\'M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z\'/%3E%3C/svg%3E")`; // cspell:disable-line
      document.documentElement.style.setProperty(`--${cssVariableKey}-accordion-button-icon`, accordion_button);
      document.documentElement.style.setProperty(`--${cssVariableKey}-accordion-button-icon--active`, accordion_button);

      return 0;
    }

    const colorLighter40 = colord(color).lighten(0.40).toHex();
    const colorLighter30 = colord(color).lighten(0.30).toHex();
    const colorLighter20 = colord(color).lighten(0.20).toHex();
    const colorLighter10 = colord(color).lighten(0.10).toHex();

    const colorRgb = this.hex_color_to_rgb_array(color);
    const textColor = colord(color).darken(0.20).toHex();
    const emphasisTextColor = colord(color).darken(0.30).toHex();
    let subtleBgColor = color;
    const subtleBorderColor = colord(color).lighten(0.33).toHex();
    const emphasisBorderColor = colord(color).darken(0.30).toHex();
    const colorActive = colord(color).lighten(0.15).toHex();
    const colorHover = colord(color).lighten(0.10).toHex();
    const text_color = this.hex_contrasted_color(color, { dark_output: "#000000", light_output: "#FFFFFF", threshold: 186 });

    const text_shadow = this.hex_contrasted_color(
      colord(text_color).toHex(), {
      light_output: "none",
      dark_output: "var(--gt-text-shadow-sm)",
      threshold: 186,
      },
    );

    const subtleBgColorContrastThreshold = 1.1;
    if (this.color_contrast_ratio("#ffffff", colorLighter40) > subtleBgColorContrastThreshold){
      subtleBgColor = colorLighter40;
    } else if (this.color_contrast_ratio("#ffffff", colorLighter30) > subtleBgColorContrastThreshold){
      subtleBgColor = colorLighter30;
    } else if (this.color_contrast_ratio("#ffffff", colorLighter20) > subtleBgColorContrastThreshold){
      subtleBgColor = colorLighter20;
    } else if (this.color_contrast_ratio("#ffffff", colorLighter10) > subtleBgColorContrastThreshold){
      subtleBgColor = colorLighter10;
    }

    document.documentElement.style.setProperty(`--${cssVariableKey}`, color);
    document.documentElement.style.setProperty(`--${cssVariableKey}-rgb`, colorRgb);
    document.documentElement.style.setProperty(`--${cssVariableKey}-text`, textColor);
    document.documentElement.style.setProperty(`--${cssVariableKey}-text-emphasis`, emphasisTextColor);
    document.documentElement.style.setProperty(`--${cssVariableKey}-text-bg-color`, text_color);
    document.documentElement.style.setProperty(`--${cssVariableKey}-text-shadow`, text_shadow);
    document.documentElement.style.setProperty(`--${cssVariableKey}-bg-subtle`, subtleBgColor);
    document.documentElement.style.setProperty(`--${cssVariableKey}-border-subtle`, subtleBorderColor);
    document.documentElement.style.setProperty(`--${cssVariableKey}-border-emphasis`, emphasisBorderColor);
    document.documentElement.style.setProperty(`--${cssVariableKey}--active`, colorActive);
    document.documentElement.style.setProperty(`--${cssVariableKey}--active-rgb`, this.hex_color_to_rgb_array(colorActive));
    document.documentElement.style.setProperty(`--${cssVariableKey}--hover`, colorHover);
    document.documentElement.style.setProperty(`--${cssVariableKey}--hover-rgb`, this.hex_color_to_rgb_array(colorHover));

    //* ------ BUTTON BG START ------ *//
    const button_bg          = colord(color).toHex();
    const button_bg_hover    = colord(color).darken(0.05).toHex();
    const button_bg_active   = colord(color).darken(0.08).toHex();
    const button_bg_disabled = colord(color).darken(0.20).toHex();

    document.documentElement.style.setProperty(`--${cssVariableKey}-btn-bg`, button_bg);
    document.documentElement.style.setProperty(`--${cssVariableKey}-btn-bg--hover`, button_bg_hover);
    document.documentElement.style.setProperty(`--${cssVariableKey}-btn-bg--active`, button_bg_active);
    document.documentElement.style.setProperty(`--${cssVariableKey}-btn-bg--disabled`, button_bg_disabled);
    //* ------ BUTTON BG END ------ *//


    //* ------ BUTTON TEXT START ------ *//
    document.documentElement.style.setProperty(`--${cssVariableKey}-btn-color`, text_color);
    document.documentElement.style.setProperty(`--${cssVariableKey}-btn-color--hover`, text_color);
    document.documentElement.style.setProperty(`--${cssVariableKey}-btn-color--active`, text_color);
    document.documentElement.style.setProperty(`--${cssVariableKey}-btn-color--disabled`, text_color);

    //* ------ BUTTON TEXT END ------ *//


    //* ------ BUTTON BORDER START ------ *//
    const button_border          = colord(color).darken(0.10).toHex();
    const button_border_hover    = colord(color).darken(0.11).toHex();
    const button_border_active   = colord(color).darken(0.13).toHex();
    const button_border_disabled = colord(color).darken(0.20).toHex();

    document.documentElement.style.setProperty(`--${cssVariableKey}-btn-border-color`, button_border);
    document.documentElement.style.setProperty(`--${cssVariableKey}-btn-border-color--hover`, button_border_hover);
    document.documentElement.style.setProperty(`--${cssVariableKey}-btn-border-color--active`, button_border_active);
    document.documentElement.style.setProperty(`--${cssVariableKey}-btn-border-color--disabled`, button_border_disabled);
    //* ------ BUTTON BORDER END ------ *//

    //* ------ BADGE START ------ *//
    document.documentElement.style.setProperty(`--${cssVariableKey}-badge-color`, text_color);
    //* ------ BADGE END ------ *//

    //* ------ Table START ------ *//
    document.documentElement.style.setProperty(`--${cssVariableKey}-table-border-color`,  colord(color).lighten(0.30).toHex() );
    document.documentElement.style.setProperty(`--${cssVariableKey}-table-color`,         colord(color).darken(0.20).toHex()  );
    document.documentElement.style.setProperty(`--${cssVariableKey}-table-bg`,            subtleBgColor                       );
    document.documentElement.style.setProperty(`--${cssVariableKey}-table-striped-bg`,    colord(color).lighten(0.30).toHex() );
    document.documentElement.style.setProperty(`--${cssVariableKey}-table-striped-color`, textColor                           );
    document.documentElement.style.setProperty(`--${cssVariableKey}-table-active-bg`,     colord(color).lighten(0.10).toHex() );
    document.documentElement.style.setProperty(`--${cssVariableKey}-table-active-color`,  colord(color).darken(0.40).toHex()  );
    document.documentElement.style.setProperty(`--${cssVariableKey}-table-hover-bg`,      colord(color).lighten(0.20).toHex() );
    document.documentElement.style.setProperty(`--${cssVariableKey}-table-hover-color`,   colord(color).darken(0.35).toHex()  );
    //* ------ Table END ------ *//

    //* ------ ACCORDION START ------ *//
    const accordion_button_color = colord(color).darken(0.20).toRgbString();
    const accordion_button = `url("data:image/svg+xml,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'0 0 16 16\' fill=\'${accordion_button_color}\'%3E%3Cpath fill-rule=\'evenodd\' d=\'M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z\'/%3E%3C/svg%3E")`; // cspell:disable-line

    document.documentElement.style.setProperty(`--${cssVariableKey}-accordion-button-icon`, accordion_button);
    document.documentElement.style.setProperty(`--${cssVariableKey}-accordion-button-icon--active`, accordion_button);
    //* ------ ACCORDION END ------ *//


    //* ------ ALERT START ------ *//
    const alert_text_color = colord(color).darken(0.20).toHex();
    const alert_btn_color = colord(color).darken(0.20).toRgbString();
    const alert_btn = `url("data:image/svg+xml,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'0 0 16 16\' fill=\'${alert_btn_color}\'%3E%3Cpath d=\'M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z\'/%3E%3C/svg%3E")`; // cspell:disable-line

    document.documentElement.style.setProperty(`--${cssVariableKey}-alert-text`, alert_text_color);
    document.documentElement.style.setProperty(`--${cssVariableKey}-alert-close-btn`, alert_btn);
    //* ------ ALERT END ------ *//

    // if(cssVariableKey === "gt-h1-color"){}

    // if(cssVariableKey === "gt-h2-color"){}

    // if(cssVariableKey === "gt-h-bg-color"){
    // }

    if (cssVariableKey === "gt-hamburger") {
      const gtHamburgerIcon = `url("data:image/svg+xml,%3csvg xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'0 0 30 30\'%3e%3cpath stroke=\'rgb%28${colorRgb}%29\' stroke-linecap=\'round\' stroke-miterlimit=\'10\' stroke-width=\'2\' d=\'M4 7h22M4 15h22M4 23h22\'/%3e%3c/svg%3e")`; // cspell:disable-line

      const gtCloseMainNavIcon = `url(\"data:image/svg+xml,%3csvg xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'0 0 16 16\' fill=\'rgb%28${colorRgb}%29\'%3e%3cpath d=\'M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z\'/%3e%3c/svg%3e\")`; // cspell:disable-line

      const gtToastCloseIcon = `url(\"data:image/svg+xml,%3csvg xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'0 0 16 16\' fill=\'rgb%28${colorRgb}%29\'%3e%3cpath d=\'M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z\'/%3e%3c/svg%3e\")`; // cspell:disable-line

      document.documentElement.style.setProperty("--gt-navbar-toggler-icon", gtHamburgerIcon);
      document.documentElement.style.setProperty("--gt-navbar-close-icon", gtCloseMainNavIcon);
      document.documentElement.style.setProperty("--gt-toast-close-icon", gtToastCloseIcon);
    }

    // if(cssVariableKey === "bs-body-bg"){}

    if(cssVariableKey === "bs-body-color"){
      document.documentElement.style.setProperty("--bs-emphasis-color", textColor);
      document.documentElement.style.setProperty("--bs-emphasis-color-rgb", textColor);
    }

    if(cssVariableKey === "bs-primary"){
      const menu_carret_icon = `url("data:image/svg+xml,%3C%3Fxml version=\'1.0\' encoding=\'utf-8'%3F%3E%3Csvg viewBox=\'168.546 81.003 192.602 318.266\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Cpath style=\'fill: rgb(${colorRgb});\' d=\'M 223.692 90.439 L 351.692 217.539 C 357.992 224.639 361.092 232.839 361.092 240.139 C 361.092 247.439 357.967 256.519 351.717 262.769 L 223.717 389.869 C 214.561 399.025 200.807 401.769 188.837 396.812 C 176.867 391.855 169.092 381.939 169.092 368.139 L 169.092 113.039 C 169.092 100.099 176.873 88.419 188.842 83.459 C 200.811 78.499 214.592 81.269 223.692 90.439 Z'/%3E%3C/svg%3E")`; // cspell:disable-line

      document.documentElement.style.setProperty("--gt-menu-icon-carret", menu_carret_icon);
    }

    // if(cssVariableKey === "bs-secondary"){}

    // if(cssVariableKey === "bs-success"){}

    // if(cssVariableKey === "bs-info"){}

    // if(cssVariableKey === "bs-warning"){}

    // if(cssVariableKey === "bs-danger"){}

    if(cssVariableKey === "bs-body-bg" || cssVariableKey === "gt-container-bg") {

      setTimeout(() => {
        const body_bg = getComputedStyle(document.documentElement).getPropertyValue("--bs-body-bg").toLowerCase().trim();
        const container_bg = getComputedStyle(document.documentElement).getPropertyValue("--gt-container-bg").toLowerCase().trim();

        if (body_bg === container_bg) {
          document.documentElement.style.setProperty("--gt-container-border", "var(--bs-border-width) solid transparent");
        } else {
          document.documentElement.style.setProperty("--gt-container-border", "var(--bs-border-width) var(--bs-border-style) var(--bs-border-color)");
        }
      }, 1000);
    }
  } //* setCSSColor()


  disconnect(){
    this.log_function("disconnect");

    this.element.removeEventListener("turbo:frame-load", this.boundInitializeTool);

    this.inputTargets.forEach(input => {
      input.removeEventListener("click", this.boundActivateColoris);
    });
  } //* disconnect()

  //? does the browser suport eyedroppers?
  get eye_dropper_suported() {
    this.log_function("get eye_dropper_suported");

    return window.EyeDropper;
  } //* eye_dropper_suported()

  get is_open() {
    this.log_function("get is_open");

    if (typeof localStorage === "undefined") return false;
    return localStorage.getItem("color_picker_9000_open") || false;
  } //* is_open()

  get saved_color_values() {
    this.log_function("get saved_color_values");

    if (typeof localStorage === "undefined") return {};
    return JSON.parse(localStorage.getItem("color_picker_9000_colors") || "{}");
  } //* saved_color_values()
}
