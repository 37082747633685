import React, { useState, useEffect, Fragment } from "react";
import { Button, Form, Offcanvas } from "react-bootstrap";
import axios from "axios";
import find from "lodash/find";
import orderBy from "lodash/orderBy";
import isEqual from "lodash/isEqual";
import intersection from "lodash/intersection";
import { useAssetLoader } from "mixins";

/**
 *  @returns {void}
 */
function FontPicker() {
  useAssetLoader(this);
  const [show, setShow] = useState(false);
  const [google_fonts, set_google_fonts] = useState([]);
  const [fontsAppended, setFontsAppended] = useState(false);
  const [selectedFont, setSelectedFont] = useState(false);
  const [editFont, setEditFont] = useState("primary");
  const [GTvar, setGTvar] = useState({});
  const [whats_loaded, set_whats_loaded] = useState([]);
  const [favorite_fonts, set_favorite_fonts] = useState([]);
  const [favorite_font_pairs, set_favorite_fonts_pairs] = useState([]);

  const debug = false;

  let styleElement = false;

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const text_transforms = ["none", "capitalize", "uppercase", "lowercase"];

  const local_fonts = [
    {
      family: "Alphaclouds",
      "variants": ["regular"],
      "subsets": ["latin"],
      "version": "V1",
      "lastModified": "2010-06-17",
      "category": "sans-serif",
      "kind": "webfonts#webfont",
    },
    {
      family: "Anthofont",
      "variants": ["regular"],
      "subsets": ["latin"],
      "version": "V1",
      "lastModified": "2024-01-31",
      "category": "sans-serif",
      "kind": "webfonts#webfont",
    },
    {
      family: "Aspekta",
      "variants": ["50", "100", "150", "200", "250", "300", "350", "400", "450", "500", "550", "600", "650", "700", "750", "800", "850", "900", "950", "1000"],
      "subsets": ["latin"],
      "version": "v2.000",
      "lastModified": "2022-09-19",
      "category": "sans-serif",
      "kind": "webfonts#webfont",
    },
    {
      family: "Benguiat",
      "variants": ["regular", "italic", "700", "700-italic"],
      "subsets": ["latin"],
      "version": "V1",
      "lastModified": "2018-11-30",
      "category": "serif",
      "kind": "webfonts#webfont",
    },
    {
      family: "Bridgnorth",
      "variants": ["regular", "700"],
      "subsets": ["latin"],
      "version": "V?",
      "lastModified": "2010-06-17",
      "category": "serif",
      "kind": "webfonts#webfont",
    },
    {
      family: "BrushscriptBt",
      "variants": ["regular"],
      "subsets": ["latin"],
      "version": "V?",
      "lastModified": "2006-09-18",
      "category": "serif",
      "kind": "webfonts#webfont",
    },
    {
      family: "Busorama",
      "variants": ["regular"],
      "subsets": ["latin"],
      "version": "X",
      "lastModified": "xxxx-xx-xx",
      "category": "sans-serif",
      "kind": "webfonts#webfont",
    },
    {
      family: "Channel",
      "variants": ["regular"],
      "subsets": ["latin"],
      "version": "X",
      "lastModified": "xxxx-xx-xx",
      "category": "sans-serif",
      "kind": "webfonts#webfont",
    },
    {
      family: "Copperplate Gothic Bold V2",
      "variants": ["regular"],
      "subsets": ["latin"],
      "version": "2",
      "lastModified": "xxxx-xx-xx",
      "category": "sans-serif",
      "kind": "webfonts#webfont",
    },
    {
      family: "Futura Md",
      "variants": ["600"],
      "subsets": ["latin"],
      "version": "X",
      "lastModified": "xxxx-xx-xx",
      "category": "sans-serif",
      "kind": "webfonts#webfont",
    },
    {
      family: "IrisUPC Bold",
      "variants": ["regular"],
      "subsets": ["latin"],
      "version": "V1",
      "lastModified": "xxxx-xx-xx",
      "category": "sans-serif",
      "kind": "webfonts#webfont",
    },
    {
      family: "Khadija",
      "variants": ["regular"],
      "subsets": ["latin"],
      "version": "V1",
      "lastModified": "xxxx-xx-xx",
      "category": "sans-serif",
      "kind": "webfonts#webfont",
    },
    {
      family: "Mangiola",
      "variants": ["100", "400", "700", "100-italic", "400-italic", "700-italic"],
      "subsets": ["latin"],
      "version": "X",
      "lastModified": "2023-5-25",
      "category": "sans-serif",
      "kind": "webfonts#webfont",
    },
    {
      family: "Megante",
      "variants": ["regular"],
      "subsets": ["latin"],
      "version": "V1",
      "lastModified": "2020-01-31",
      "category": "sans-serif",
      "kind": "webfonts#webfont",
    },
    {
      family: "Monotype Corsiva",
      "variants": ["regular"],
      "subsets": ["latin"],
      "version": "V1",
      "lastModified": "xxxx-xx-xx",
      "category": "serif",
      "kind": "webfonts#webfont",
    },
    {
      family: "OnStageSerial Bold",
      "variants": ["regular"],
      "subsets": ["latin"],
      "version": "V1",
      "lastModified": "xxxx-xx-xx",
      "category": "san-serif",
      "kind": "webfonts#webfont",
    },
    {
      family: "OPTIAggie Solid",
      "variants": ["regular"],
      "subsets": ["latin"],
      "version": "V1",
      "lastModified": "xxxx-xx-xx",
      "category": "sans-serif",
      "kind": "webfonts#webfont",
    },
    {
      family: "Park Avenue",
      "variants": ["regular"],
      "subsets": ["latin"],
      "version": "V001.005",
      "lastModified": "xxxx-xx-xx",
      "category": "sans-serif",
      "kind": "webfonts#webfont",
    },
    {
      family: "Quostige",
      "variants": ["regular"],
      "subsets": ["latin"],
      "version": "V1",
      "lastModified": "xxxx-xx-xx",
      "category": "sans-serif",
      "kind": "webfonts#webfont",
    },
    {
      family: "Remond",
      "variants": ["regular"],
      "subsets": ["latin"],
      "version": "V1",
      "lastModified": "xxxx-xx-xx",
      "category": "sans-serif",
      "kind": "webfonts#webfont",
    },
    {
      family: "Titania Outline",
      "variants": ["regular"],
      "subsets": ["latin"],
      "version": "VX",
      "lastModified": "xxxx-xx-xx",
      "category": "sans-serif",
      "kind": "webfonts#webfont",
    },
    {
      family: "Titania Regular",
      "variants": ["regular"],
      "subsets": ["latin"],
      "version": "VX",
      "lastModified": "xxxx-xx-xx",
      "category": "sans-serif",
      "kind": "webfonts#webfont",
    },
    {
      family: "Titania Shadow",
      "variants": ["regular"],
      "subsets": ["latin"],
      "version": "VX",
      "lastModified": "xxxx-xx-xx",
      "category": "sans-serif",
      "kind": "webfonts#webfont",
    },
    {
      family: "ZonaPro",
      "variants": [/* "regular", */ "700"],
      "subsets": ["latin", "greek", "cyrillic"],
      "version": "V1",
      "lastModified": "xxxx-xx-xx",
      "category": "sans-serif",
      "kind": "webfonts#webfont",
    },
  ];


  //* Copy settings to clip board
  const copySettings = () => {
    if(debug) console.log("%c font_picker_9000#copySettings()", "color: lightblue;");

    const text = JSON.stringify(GTvar);

    navigator.clipboard.writeText(text)
    .then(() => {
      if(debug) console.log("Text copied to clipboard.");
      return text;
    })
    .catch((error) => {
      if(debug) console.error("Failed to copy text: ", error);
      return error;
    });
  };

  const font_pair_is_saved = () => {
    return favorite_font_pairs.some((pair) => isEqual(pair, GTvar));
  };

  const save_font_pair = () => {
    if (!font_pair_is_saved()) {
      const updatedFontPairs = [...favorite_font_pairs, GTvar];
      set_favorite_fonts_pairs(updatedFontPairs);
    }
  };

  const delete_font_pair = (index) => {
    const updatedFontPairs = [...favorite_font_pairs];
    updatedFontPairs.splice(index, 1);
    set_favorite_fonts_pairs(updatedFontPairs);
  };

  const set_font_pair = (index) => {
    const selected_font_pair = favorite_font_pairs[index];
    if(debug) console.log("set font pair at index", index);
    if(debug) console.log(selected_font_pair);

    let h1_full_details = false;
    let h2_full_details = false;

    if(selected_font_pair.h1_font_source === "google"){
      // load_full_typeface(selected_font_pair.h1_font_family);
      h1_full_details = find(google_fonts, (font) => font.family === selected_font_pair.h1_font_family);
    } else if(selected_font_pair.h1_font_source === "local") {
      h1_full_details = find(local_fonts, (font) => font.family === selected_font_pair.h1_font_family);
    }

    if(selected_font_pair.h2_font_source === "google"){
      // load_full_typeface(selected_font_pair.h2_font_family);
      h2_full_details = find(google_fonts, (font) => font.family === selected_font_pair.h2_font_family);
    } else if(selected_font_pair.h2_font_source === "local") {
      h2_full_details = find(local_fonts, (font) => font.family === selected_font_pair.h2_font_family);
    }

    const h1_edit_data = {
      font_to_edit: "primary",
      font_family: selected_font_pair.h1_font_family,
      font_to_load: JSON.stringify(h1_full_details),
      font_variant: selected_font_pair.h1_font_variant,
      font_source: selected_font_pair.h1_font_source,
      font_weight: selected_font_pair.h1_font_weight,
      font_style: selected_font_pair.h1_font_style,
      text_transform: selected_font_pair.h1_text_transform,
      line_height: selected_font_pair.h1_line_height,
    };

    const h2_edit_data = {
      font_to_edit: "secondary",
      font_family: selected_font_pair.h2_font_family,
      font_to_load: JSON.stringify(h2_full_details),
      font_variant: selected_font_pair.h2_font_variant,
      font_source: selected_font_pair.h2_font_source,
      font_weight: selected_font_pair.h2_font_weight,
      font_style: selected_font_pair.h2_font_style,
      text_transform: selected_font_pair.h2_text_transform,
      line_height: selected_font_pair.h2_line_height,
    };


    //* when h1 is set it then sets h2 when h2 is set it calls initGTVar
    handle_edit(h1_edit_data, () => handle_edit(h2_edit_data, initGTVar));
  };

  //* Create a style tag to be added to the body
  const load_typeface = (url, fontFamily, weight, style) => {
    if(debug) console.log("%c font_picker_9000#load_typeface()", "color: lightblue;");

    const loaded = whats_loaded.includes(url);

    if (loaded){
      if(debug) console.info("already loaded", fontFamily, weight, style);
    }else {
      const typeface = `
      @font-face {
        font-family: '${fontFamily}';
        src: url('${url}') format('truetype');
        font-weight: ${weight};
        font-style: ${style};
      }
      `;

      set_whats_loaded(old_state => [...old_state, url]);

      const NewFont = document.createElement("style");
      NewFont.innerHTML = `${typeface}`;
      document.head.appendChild(NewFont);
    }
  };

  const toggle_font_in_favorites = (font) => {
    const { family } = font; //* The name of the font

    if (favorite_fonts.includes(family)) {
      //* Font exists in favorites, remove it
      const filteredFonts = favorite_fonts.filter((f) => f !== family);
      set_favorite_fonts(filteredFonts);
    } else {
      //* Font does not exist in favorites, add it
      const updatedFonts = [...favorite_fonts, family];
      set_favorite_fonts(updatedFonts);
    }
  };

  const is_font_in_favorites = (font) => {
    return favorite_fonts.includes(font);
  };


  const load_full_typeface = (font_family) => {
    const url = `https://fonts.googleapis.com/css2?family=${font_family.replace(/\s+/g, "+")}:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap`;
    const loaded = whats_loaded.includes(url);

    if (loaded){
      if(debug) console.info("already loaded full typeface", font_family);
    } else {
      this.load_style(url);
      set_whats_loaded(old_state => [...old_state, url]);
    }
  };

  const handle_edit = (data, callback) => {
    if(debug) console.log("%c font_picker_9000#handle_edit()", "color: lightblue;");
    let newData = data;
    let prefix = "";

    if(newData.font_to_load){
      const font_to_load = JSON.parse(newData.font_to_load);
      setSelectedFont(font_to_load);
    }

    if (newData.font_family && newData.font_variant && newData.font_source === "google"){
      load_full_typeface(newData.font_family);
    }

    switch (newData.font_to_edit || editFont) {
      case "primary":
        if (newData.font_family)    document.documentElement.style.setProperty("--gt-logo-typeface-primary", newData.font_family);
        if (newData.font_weight)    document.documentElement.style.setProperty("--gt-logo-fontweight-primary", newData.font_weight);
        if (newData.font_style)     document.documentElement.style.setProperty("--gt-logo-fontstyle-primary", newData.font_style);
        if (newData.text_transform) document.documentElement.style.setProperty("--gt-logo-text-transform-primary", newData.text_transform);
        if (newData.line_height)    document.documentElement.style.setProperty("--gt-logo-line-height-primary", newData.line_height);
        prefix = "h1_";
        break;
      case "secondary":
        if (newData.font_family)    document.documentElement.style.setProperty("--gt-logo-typeface-secondary", newData.font_family);
        if (newData.font_weight)    document.documentElement.style.setProperty("--gt-logo-fontweight-secondary", newData.font_weight);
        if (newData.font_style)     document.documentElement.style.setProperty("--gt-logo-fontstyle-secondary", newData.font_style);
        if (newData.text_transform) document.documentElement.style.setProperty("--gt-logo-text-transform-secondary", newData.text_transform);
        if (newData.line_height)    document.documentElement.style.setProperty("--gt-logo-line-height-secondary", newData.line_height);
          prefix = "h2_";
        break;
      default:
        if(debug) console.error("no valid font to edit");
        break;
    }

    const newDataPrefixed = {};
    Object.keys(newData).forEach(key => {
      if (key !== "font_to_load") {
        newDataPrefixed[prefix + key] = newData[key];
      }
    });

    delete newDataPrefixed[prefix + "font_to_edit"]; //* So it wont be saved to GT

    if(debug) console.log(newDataPrefixed);

    setGTvar({ ...GTvar, ...newDataPrefixed }, callback);
  };

  const get_css_var_value = (variablename) => {
    if(debug) console.log("%c font_picker_9000#get_css_var_value()", "color: lightblue;");

    return getComputedStyle(document.documentElement).getPropertyValue(variablename).trim();
  };

  const handle_edit_font_change = (event) => {
    if(debug) console.log("%c font_picker_9000#handle_edit_font_change()", "color: lightblue;");
    const edit_font = event.target.value;

    let font_load_source = false;
    let selected_font_family = "";
    let new_selected_font = {};

    switch (edit_font) {
      case "primary":
        font_load_source = GTvar.h1_font_source;
        selected_font_family = GTvar.h1_font_family;
        break;
      case "secondary":
        font_load_source = GTvar.h2_font_source;
        selected_font_family = GTvar.h2_font_family;
        break;
      default:
        if(debug) console.error("unknown edit font");
        break;
    }


    switch (font_load_source) {
      case "local":
        new_selected_font = find(local_fonts, { family: selected_font_family });
        setSelectedFont(new_selected_font);
        break;
      case "google":
        new_selected_font = find(google_fonts, { family: selected_font_family });
        setSelectedFont(new_selected_font);
        break;
      default:
        if(debug) console.error("unknown font load source");
        break;
    }

    setEditFont(edit_font);
  };

  const subsets_as_flags = (subsets) => {
    if(debug) console.log("%c font_picker_9000#subsets_as_flags()", "color: lightblue;");

    const output = [];


    //* Coment these out the the rest in to get more details
    if(subsets.includes("latin") || subsets.includes("latin-ext")) output.push("🇺🇸");
    if(subsets.includes("greek") || subsets.includes("greek-ext")) output.push("🇬🇷");

    // if(subsets.includes("latin") && !subsets.includes("latin-ext")) output.push("🇺🇸");
    // if(subsets.includes("latin-ext") && subsets.includes("latin")) output.push("🇺🇸✨");
    // if(subsets.includes("greek") && !subsets.includes("greek-ext")) output.push("🇬🇷");
    // if(subsets.includes("greek-ext") && subsets.includes("greek")) output.push("🇬🇷✨");

    // if(subsets.includes("cyrillic") && !subsets.includes("cyrillic-ext")) output.push("🇧🇬");
    // if(subsets.includes("cyrillic-ext") && subsets.includes("cyrillic")) output.push("🇧🇬✨");

    // if(subsets.includes("bengali") || subsets.includes("devanagari") || subsets.includes("gujarati") || subsets.includes("gurmukhi") || subsets.includes("kannada") || subsets.includes("malayalam") || subsets.includes("oriya") || subsets.includes("tamil") || subsets.includes("telugu")){
    //   output.push("🇮🇳");
    // }

    // if(subsets.includes("hebrew")) output.push("🇮🇱");
    // if(subsets.includes("vietnamese")) output.push("🇻🇳");
    // if(subsets.includes("thai")) output.push("🇹🇭");
    // if(subsets.includes("korean")) output.push("🇰🇷");
    // if(subsets.includes("arabic")) output.push("🇦🇪");
    // if(subsets.includes("khmer")) output.push("🇰🇭");
    // if(subsets.includes("myanmar")) output.push("🇲🇲");
    // if(subsets.includes("sinhala")) output.push("🇱🇰");

    return output.join("\u00A0");
  };


  const get_line_height = () => {
    return (editFont === "primary" && GTvar.h1_line_height) || (editFont === "secondary" && GTvar.h2_line_height);
  };

  //* Save favorite_font_pairs to local storage whenever it changes
  useEffect(() => {
    const savedFontPairs = JSON.stringify(favorite_font_pairs);
    if (savedFontPairs && savedFontPairs !== "[]") {
      localStorage.setItem("favorite_font_pairs", savedFontPairs);
    }
  }, [favorite_font_pairs]);

  //* Load favorite_fonts_pairs from local storage when the component mounts
  useEffect(() => {
    const savedFontPairs = JSON.parse(localStorage.getItem("favorite_font_pairs"));
    if (savedFontPairs) {
      set_favorite_fonts_pairs(savedFontPairs);
    }
  }, []);

  //* Save favorite_fonts to local storage whenever it changes
  useEffect(() => {
    const savedFonts = JSON.stringify(favorite_fonts);
    if (savedFonts && savedFonts !== "[]") {
      localStorage.setItem("favorite_fonts", savedFonts);
    }
  }, [favorite_fonts]);

  //* Load favorite_fonts from local storage when the component mounts
  useEffect(() => {
    const savedFonts = JSON.parse(localStorage.getItem("favorite_fonts"));
    if (savedFonts) {
      set_favorite_fonts(savedFonts);
    }
  }, []);

  //* Get Initial Values (get_initial_values)
  useEffect(() => {
    if(debug) console.log("%c font_picker_9000#useEffect get_initial_values", "color: lightblue;");
    styleElement = document.createElement("style");
    styleElement.setAttribute("id", "fp900Styles");
    styleElement.dataset.loaded = "[]";
    document.head.appendChild(styleElement);

    initGTVar();
  }, []);

  const initGTVar = (callback) => {
    setGTvar({
      h1_font_source: get_css_var_value("--gt-logo-loadfrom-primary").replace(/'/g, ""),
      h1_font_family: get_css_var_value("--gt-logo-typeface-primary"),
      h1_font_weight: get_css_var_value("--gt-logo-fontweight-primary"),
      h1_font_style: get_css_var_value("--gt-logo-fontstyle-primary").replace(/'/g, ""),
      h1_text_transform: get_css_var_value("--gt-logo-text-transform-primary"),
      h1_line_height: get_css_var_value("--gt-logo-line-height-primary"),
      h1_font_variant: get_css_var_value("--gt-logo-fontvariant-primary").replace(/'/g, ""),

      h2_font_source: get_css_var_value("--gt-logo-loadfrom-secondary").replace(/'/g, ""),
      h2_font_family: get_css_var_value("--gt-logo-typeface-secondary"),
      h2_font_weight: get_css_var_value("--gt-logo-fontweight-secondary"),
      h2_font_style: get_css_var_value("--gt-logo-fontstyle-secondary").replace(/'/g, ""),
      h2_text_transform: get_css_var_value("--gt-logo-text-transform-secondary"),
      h2_line_height: get_css_var_value("--gt-logo-line-height-secondary"),
      h2_font_variant: get_css_var_value("--gt-logo-fontvariant-secondary").replace(/'/g, ""),
    }, callback);
  };

  //* Load fonts when the tool in oppend
  useEffect(() => {
    if(debug) console.log("%c font_picker_9000#useEffect get values from google fonts API", "color: lightblue;");

    if (show && google_fonts.length === 0) {
      axios
      .get("https://www.googleapis.com/webfonts/v1/webfonts?key=AIzaSyACk8dpzEDCNlvouzCiz1fHoN_y-bxg8sw")
      .then((responce) => {
        // if(debug) console.log(responce.data);
        const fonts = responce.data.items;

        const sortedFonts = orderBy(
          fonts,
          (font) => {
            const commonSubsets = intersection(font.subsets, ["greek", "greek-ext"]);
            return commonSubsets.length ? 0 : 1;
          },
          "asc",
        );

        let selected_font;
        switch (GTvar.h1_font_source) {
          case "local":
            selected_font = find(local_fonts, { family: GTvar.h1_font_family });
            setSelectedFont(selected_font);
            break;
          case "google":
            selected_font = find(sortedFonts, { family: GTvar.h1_font_family });
            setSelectedFont(selected_font);
            break;
          default:
            if(debug) console.error("unknown font source");
            break;
        }

        set_google_fonts(sortedFonts);
        return responce;
      })
      .catch((error)=>{
        if(debug) console.error(error);
        window.alert("Could not connect to google fonts API");
        return error;
      });
    }
  }, [show]);

  //* This runs everytime the value of fonts is changed
  useEffect(() => {
    if(debug) console.log("%c font_picker_9000#useEffect when google fonts get loaded", "color: lightblue;");
    if (google_fonts.length > 0 && !fontsAppended) {
      google_fonts.forEach((font) => {
        // if(debug) console.log("append")
        const files = font?.files || {};

        let url;
        let weight;
        let style;

        if (Object.prototype.hasOwnProperty.call(files, "regular")){
          url = files.regular;
          weight = 400;
          style = "regular";
        } else {
          url = Object.entries(files)[0][1];
          weight = Object.entries(files)[0][0];
          style = weight.includes("italic") ? "italic" : "regular";
        }

        url = url.replace("http://", "https://");

        const fontFamily = font.family;

        //* Enable me to load fonts
        load_typeface(url, fontFamily, weight, style);
      });
      setFontsAppended(true);
    }
  }, [google_fonts]);


  return (
    <Fragment>
      {/* //! If you make changes to the look of this button the same changes need to be made in ./index.jsx */}
      <Button
        variant="white"
        disabled={show}
        onClick={handleShow}
        className="font-monospace w-100 h-100"
      >
        <svg viewBox="0 0 448 512" className="svg-inline--fa fa-text fa-2xl">
          <path d="M64 96v32c0 17.7-14.3 32-32 32s-32-14.3-32-32V80C0 53.5 21.5 32 48 32H224 400c26.5 0 48 21.5 48 48v48c0 17.7-14.3 32-32 32s-32-14.3-32-32V96H256l0 320h48c17.7 0 32 14.3 32 32s-14.3 32-32 32H144c-17.7 0-32-14.3-32-32s14.3-32 32-32h48l0-320H64z" fill="#000000"></path>
        </svg>
        <span className="visually-hidden">Font Picker</span>
      </Button>

      <Offcanvas show={show} onHide={handleClose} placement="start" scroll={true} backdrop={false}>
        <Offcanvas.Header closeButton className="bg-white text-black fw-bold">
          <Offcanvas.Title>Font Picker 9000</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="p-0 bg-white text-black">

          <div className="position-sticky top-0 bg-white border-bottom px-3 z-1">
            <strong>1. Select a font to edit</strong>
            <Form.Select aria-label="Font to Edit" value={editFont} onChange={handle_edit_font_change} className="bg-white text-black">
              <option value="primary">Main Font</option>
              <option value="secondary">Secondary</option>
            </Form.Select>
            <br />
            <strong>2. Pick a font to preview</strong>
            <br />
          </div>

          {/* { selectedFont &&
            <div
              className={"text-bg-primary rounded-0 text-decoration-none d-block w-100 text-start p-0 ps-3 mb-3 fs-3"}
              style={{ fontFamily: selectedFont.family, order: -2 }}
            >
              { is_font_in_favorites(selectedFont.family) ? "⭐" : "" } { selectedFont.family } { subsets_as_flags(selectedFont.subsets) }
            </div>
          } */}

          <h3 className="mb-3 mt-3 px-2 py-1 border-bottom border-2">Saved Font Pairs</h3>
          <div>
            { favorite_font_pairs.map((font_pair, key) => {

              const h1_span_styles = {fontFamily: font_pair.h1_font_family, fontWeight: font_pair.h1_font_weight, textTransform: font_pair.h1_text_transform, lineHeight: font_pair.h1_line_height};
              const h2_span_styles = {fontFamily: font_pair.h2_font_family, fontWeight: font_pair.h2_font_weight, textTransform: font_pair.h2_text_transform, lineHeight: font_pair.h1_line_height };

              const h1_span_text = font_pair.h1_font_family;
              const h2_span_text = font_pair.h2_font_family;

              const selected = JSON.stringify(font_pair) === JSON.stringify(GTvar);

              return (
                <div key={`font_pair-${key}`} className="d-flex justify-content-between w-100 mb-2 px-2">
                  <Button variant="link" className="text-black text-decoration-none fs-5" disabled={selected} onClick={ () => set_font_pair(key) }>

                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" className="svg-inline--fa fa-check me-2" fill={ selected ? "var(--bs-success)" : "transparent" }>
                      <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/>
                    </svg>

                    <span style={h1_span_styles}>{h1_span_text}</span>
                    <span> / </span>
                    <span style={h2_span_styles}>{h2_span_text}</span>
                  </Button>


                  <Button variant="link" size="sm" className="outline-0" onClick={ () => delete_font_pair(key) }>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" className="svg-inline--fa fa-trash-can fa-lg" fill="var(--bs-danger)">
                      <path d="M135.2 17.7C140.6 6.8 151.7 0 163.8 0H284.2c12.1 0 23.2 6.8 28.6 17.7L320 32h96c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 96 0 81.7 0 64S14.3 32 32 32h96l7.2-14.3zM32 128H416V448c0 35.3-28.7 64-64 64H96c-35.3 0-64-28.7-64-64V128zm96 64c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16z"/>
                    </svg>
                  </Button>
                </div>
              );
            })}

            { favorite_font_pairs?.length <= 0 ? <p className="px-3 text-center text-black opacity-80" style={{opacity: 0.6}}>You do not have any saved fonts yet.</p> : null }

            <div className="d-grid gap-2">
              <Button variant="outline-success" className="mx-3" onClick={save_font_pair} disabled={font_pair_is_saved()}>
                { font_pair_is_saved() ? "Saved!" : "Save Font Pair"}
              </Button>
            </div>
          </div>

          <h3 className="mb-3 mt-3 px-2 py-1 border-bottom border-2">Local Fonts</h3>
          <div className="d-flex flex-column">
            {local_fonts.map((font, key) => {
              let font_weight;
              let font_style;
              let font_variant;
              let selected = selectedFont.family === font.family;

              if (font?.variants.indexOf("regular") >= 0){
                font_weight = 400;
                font_style = "regular";
                font_variant = "regular";
              } else if (font?.variants.indexOf("400") >= 0){
                font_weight = 400;
                font_style = "regular";
                font_variant = 400;
              } else {
                font_variant = font?.variants[0];
                font_weight = font_variant.match(/\d+/) ? font_variant.match(/\d+/)[0] : "400";
                font_style = font_variant.includes("italic") ? "italic" : "regular";
              }

              let order = 1;
              if(is_font_in_favorites(font.family)) order = -1;

              return (
                <Button
                  key={`local-${key}`}
                  variant="link"
                  data-font_family={font.family}
                  data-font_weight={font_weight}
                  data-font_style={font_style}
                  data-font_variant={font_variant}
                  data-font_source={"local"}
                  data-font_to_load={JSON.stringify(font)}
                  data-font_to_edit={editFont}
                  className={`${selected ? "text-bg-primary" : "text-black"} rounded-0 text-decoration-none d-block w-100 text-start p-0 ps-3 mb-3 fs-3`}
                  style={{ fontFamily: font.family, order: order }}
                  onClick={(event) => handle_edit(event.target.dataset)}
                >
                  { is_font_in_favorites(font.family) ? "⭐" : "" } { font.family } { subsets_as_flags(font.subsets) }
                </Button>
              );
            })}

          </div>
          <h3 className="mb-3 mt-5 px-2 py-1 border-bottom border-2">Google Fonts</h3>
          <div className="d-flex flex-column">


            {/* A font looks like this */}
            {/* {"family":"ABeeZee","variants":["regular","italic"],"subsets":["latin","latin-ext"],"version":"v22","lastModified":"2022-09-22","files":{"regular":"http://fonts.gstatic.com/s/abeezee/v22/esDR31xSG-6AGleN6tKukbcHCpE.ttf","italic":"http://fonts.gstatic.com/s/abeezee/v22/esDT31xSG-6AGleN2tCklZUCGpG-GQ.ttf"},"category":"sans-serif","kind":"webfonts#webfont"} */}

            { google_fonts.map((font, key)=> {
              let font_weight;
              let font_style;
              let font_variant;
              let selected = selectedFont.family === font.family;

              if (font?.variants.indexOf("regular") >= 0){
                font_weight = 400;
                font_style = "regular";
                font_variant = "regular";
              } else if (font?.variants.indexOf("400") >= 0){
                font_weight = 400;
                font_style = "regular";
                font_variant = 400;
              } else {
                font_variant = font?.variants[0];
                font_weight = font_variant.match(/\d+/) ? font_variant.match(/\d+/)[0] : "400";
                font_style = font_variant.includes("italic") ? "italic" : "regular";
              }

              let order = 1;
              if(is_font_in_favorites(font.family)) order = -1;

              return(
                <Button
                  key={`google-${key}`}
                  variant="link"
                  data-font_family={font.family}
                  data-font_weight={font_weight}
                  data-font_style={font_style}
                  data-font_variant={font_variant}
                  data-font_source={"google"}
                  data-font_to_load={JSON.stringify(font)}
                  data-font_to_edit={editFont}
                  className={`${selected ? "text-bg-primary" : "text-black"} rounded-0 text-decoration-none d-block w-100 text-start p-0 ps-3 mb-3 fs-3`}
                  style={{ fontFamily: font.family, order: order }}
                  onClick={(event) => handle_edit(event.target.dataset)}
                >
                  { is_font_in_favorites(font.family) ? "⭐" : "" } { font.family } { subsets_as_flags(font.subsets) }
                </Button>
              );
            })}
          </div>
        </Offcanvas.Body>
      </Offcanvas>

      <Offcanvas show={show && selectedFont} onHide={handleClose} placement="end" scroll={true} backdrop={false}>
        <Offcanvas.Header closeButton className="bg-white text-black fw-bold">
          <Offcanvas.Title>
            <span>Font Picker Customizer</span>
            <Button
              variant="link"
              onClick={ ()=> toggle_font_in_favorites(selectedFont) }
              className="ms-2 font-monospace"
            >
              { is_font_in_favorites(selectedFont?.family) ?
                <svg viewBox="0 0 576 512" className="svg-inline--fa fa-star fa-lg" fill="var(--bs-cta)">
                  <path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"/>
                </svg>
                :
                <svg viewBox="0 0 576 512" className="svg-inline--fa fa-star fa-lg" fill="var(--bs-cta)">
                  <path d="M287.9 0c9.2 0 17.6 5.2 21.6 13.5l68.6 141.3 153.2 22.6c9 1.3 16.5 7.6 19.3 16.3s.5 18.1-5.9 24.5L433.6 328.4l26.2 155.6c1.5 9-2.2 18.1-9.6 23.5s-17.3 6-25.3 1.7l-137-73.2L151 509.1c-8.1 4.3-17.9 3.7-25.3-1.7s-11.2-14.5-9.7-23.5l26.2-155.6L31.1 218.2c-6.5-6.4-8.7-15.9-5.9-24.5s10.3-14.9 19.3-16.3l153.2-22.6L266.3 13.5C270.4 5.2 278.7 0 287.9 0zm0 79L235.4 187.2c-3.5 7.1-10.2 12.1-18.1 13.3L99 217.9 184.9 303c5.5 5.5 8.1 13.3 6.8 21L171.4 443.7l105.2-56.2c7.1-3.8 15.6-3.8 22.6 0l105.2 56.2L384.2 324.1c-1.3-7.7 1.2-15.5 6.8-21l85.9-85.1L358.6 200.5c-7.8-1.2-14.6-6.1-18.1-13.3L287.9 79z"/>
                </svg>
              }
            </Button>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="bg-white text-black">
          <strong>You Have Selected:</strong>
          {/* <pre>{JSON.stringify(selectedFont, null, 2)}</pre> */}
          <div>
            <div className="small">
              <strong>Family: </strong>
              <span>{selectedFont?.family}</span>
            </div>
            <div className="small">
              <strong>Version: </strong>
              <span>{selectedFont?.version}</span>
            </div>
            <div className="small">
              <strong>Updated: </strong>
              <span>{selectedFont?.lastModified}</span>
            </div>
            <div className="small">
              <strong>Category: </strong>
              <span>{selectedFont?.category}</span>
            </div>
            <div className="small">
              <strong>Subsets: </strong>
              <span>{selectedFont?.subsets?.join(", ")}</span>
            </div>
          </div>
          <br />

          <strong className="d-block">3. Customize Font</strong>
          <br />

          <strong>Line Height</strong>
          <span>:&nbsp;</span>
          <span>{get_line_height()}</span>

          <Form.Range
            min={0}
            max={2}
            step={0.1}
            onChange={(event) => handle_edit({...event.target.dataset, line_height: event.target.value})}
            data-font_to_edit={editFont}
            value={get_line_height()}
          />
          <br />

          <strong>Variants</strong>

          { selectedFont?.variants?.map((variant, key) =>{

            let font_weight = variant.match(/\d+/g);
            font_weight = font_weight ? parseInt(font_weight.join("")) : 400;
            const font_style = variant.includes("italic") ? "italic" : "regular";

            let checked = false;
            switch (editFont) {
              case "primary":
                checked = variant === GTvar.h1_font_variant;
                break;
                case "secondary":
                  checked = variant === GTvar.h2_font_variant;
                break;
              default:
                if(debug) console.error("no valid font to have checked");
                break;
            }
            checked = checked || selectedFont?.variants.length === 1;

            return(
              <Form.Check
                key={key}
                onChange={(event) => handle_edit(event.target.dataset)}
                data-font_family={selectedFont.family}
                data-font_weight={font_weight}
                data-font_style={font_style}
                data-font_variant={variant}
                data-font_to_load={JSON.stringify(selectedFont)}
                data-font_to_edit={editFont}
                checked={checked}
                name="variant-group"
                value={variant}
                disabled={selectedFont?.variants.length <= 1}
                type='radio'
                className="ms-2"
                id={`variant-${key}`}
                label={variant}
              />
            );
          })}
          <br />

          <strong>Text Transform</strong>
          {text_transforms.map((value, key) => {
            const checked = (editFont === "primary" && GTvar.h1_text_transform === value) || (editFont === "secondary" && GTvar.h2text_transform);

            return(
              <Form.Check
                key={key}
                onChange={(event) => handle_edit(event.target.dataset)}
                data-font_to_load={JSON.stringify(selectedFont)}
                data-font_to_edit={editFont}
                data-text_transform={value}
                checked={checked}
                name="text-transform-group"
                value={value}
                type='radio'
                className="ms-2"
                id={`transform-${key}`}
                label={value}
              />
            );
          })}
          <br />

          <strong>4. Get your Settings</strong>
          <Form.Control as="textarea" readOnly rows="15" className="w-100 mb-3 d-none" value={JSON.stringify(GTvar, null, 2)}></Form.Control>
          <div className="d-grid gap-2">
            <Button variant="outline-success" size="lg" onClick={copySettings}>
              Copy Settings
            </Button>
          </div>

        </Offcanvas.Body>
      </Offcanvas>
    </Fragment>
  );
}

export default FontPicker;
